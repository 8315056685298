import { Component, OnInit } from '@angular/core';
import {StateService} from '../state.service';
import {PageTitleService} from '../page-title.service';

@Component({
  selector: 'app-outdoor',
  templateUrl: './outdoor.component.html',
  styleUrls: ['./outdoor.component.scss']
})
export class OutdoorComponent implements OnInit {
  currentPageTitle = 'OUTDOOR アウトドア  ';
  constructor(
    private stateService: StateService,
    private pageTitle: PageTitleService
  ) {
    this.stateService.isVisibleBreadcrumb = true;
    this.stateService.currentPage = true;
    this.stateService.currentPageName = this.currentPageTitle;
    this.pageTitle.changeTitle(this.currentPageTitle);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
