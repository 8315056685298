// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiHost: 'http://localhost:8885/api/',
  apiHost: 'https://us-central1-lake-side-towada.cloudfunctions.net/',
  postalCodeApiKey: 'V6tSLJfM8EGzHHBlpjDnp6dXk0WbYpKSHk5jiGq',
  firebaseConfig : {
    apiKey: 'AIzaSyBVCAQ-m5rqZa5ElCc5ii1vG__lPwalYhQ',
    authDomain: 'lake-side-towada.firebaseapp.com',
    databaseURL: 'https://lake-side-towada.firebaseio.com',
    projectId: 'lake-side-towada',
    storageBucket: 'lake-side-towada.appspot.com',
    messagingSenderId: '717930157702',
    appId: '1:717930157702:web:87935f826c94370a7b6307'
  },
  analytics: {
    id: ''
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
