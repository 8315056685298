<div class="menu">
  <ul>
    <li class="menu__bg08" routerLink="/reservation">
      <div class="menu__button__title">RESERVATION</div>
      <div class="menu__button__title-ja">ご予約</div>
    </li>
    <li class="menu__bg06" routerLink="/rate">
      <div class="menu__button__title">RATE</div>
      <div class="menu__button__title-ja">ご利用料金</div>
    </li>
    <li class="menu__bg01" routerLink="/about">
      <div class="menu__button__title">ABOUT</div>
      <div class="menu__button__title-ja">山の家とは</div>
    </li>
    <li class="menu__bg02" routerLink="/topics">
      <div class="menu__button__title">TOPICS</div>
      <div class="menu__button__title-ja">トピックス</div>
    </li>
    <li class="menu__bg03" routerLink="/facility">
      <div class="menu__button__title">FACILITY</div>
      <div class="menu__button__title-ja">宿泊施設</div>
    </li>
    <li class="menu__bg04" routerLink="/outdoor">
      <div class="menu__button__title">OUTDOOR</div>
      <div class="menu__button__title-ja">アウトドア</div>
    </li>
    <li class="menu__bg05" routerLink="/eat">
      <div class="menu__button__title">EAT</div>
      <div class="menu__button__title-ja">お食事</div>
    </li>
    <li class="menu__bg07" routerLink="/access">
      <div class="menu__button__title">ACCESS</div>
      <div class="menu__button__title-ja">アクセス</div>
    </li>
  </ul>
</div>
