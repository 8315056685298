<div class="about">
  <div class="title-box">
    <h2>ABOUT</h2>
    <div class="subtitle">自然と歴史 十和田湖の魅力</div>
  </div>
  <div class="content-box mb3">
    <div class="content-box__inner-left">
      <img src="../../assets/images/facility/facility1.png">
    </div>
    <div class="content-box__inner-right">
      <h2>レークサイド山の家</h2>
      開設以来ここには都会の日常とは隔絶した時が流れています。<br><br>
      日々変わる幻想的な風景を目の当たりにしながら1日をお過ごし頂けます。<br>
      カヌーやボートでの自然体験をする、ヒメマス漁の解禁日には１日中釣りに没頭するなど楽しみ方はあなた次第。
      また当施設はペットと泊まれるお宿としても有名です。<br>
      ご家族同様のペットとともに十和田湖の雄大な自然の中でごゆっくりお過ごしください。
    </div>
  </div>

  <div class="content-box mb3">
    <div class="content-box__inner-left">
      <img src="../../assets/images/about/about1.jpg">
    </div>
    <div class="content-box__inner-right">
      <h2>十和田湖</h2>
      典型的な二重式カルデラ湖で、海抜400m、周囲は約46Km、湖の最深部326.8mと日本第３位の美しい藍をたたえた神秘の湖です。<br><br>
      「山は富士、湖は十和田湖、広い世界に一つずつ」と、かの大町桂月が絶賛しました。<br><br>
      　波静かな湖面では、湖上遊覧船やボート遊びができ、また 湖周をめぐるサイクリングやハイキングも楽しめます。新緑や紅葉の季節はもちろん、雪の十和田湖も新鮮な味わいです。
    </div>
  </div>


  <div class="content-box mb3">
    <div class="content-box__inner-left">
      <img src="../../assets/images/about/about2.jpg">
    </div>
    <div class="content-box__inner-right">
      <h2>奥入瀬渓流</h2>
      十和田湖畔の子ノ口から焼山に至る約14kmの渓流美。千変万化の水の流れが生む躍動感あふれる景観が展開します。<br><br>奥入瀬を代表する「阿修羅の流れ」をはじめ、「雲井の滝」「銚子大滝」など主な滝だけでも十四ヶ所もあり、見どころがいっぱい。<br><br><br><br>
    </div>
  </div>
  <div class="headline">
  ＜現地管理会社＞<br>
  有限会社サンレークサイド十和田<br>
  代表　大森進<br>
  〒018-5511<br>
  秋田県十和田湖字銀山1-7<br>
  TEL /FAX 0176-75-2552<br>
  </div>
</div>
