import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public isVisibleBreadcrumb = false;
  public currentPage = false;
  public currentPageName = '';
  public breadcrumb = [];
  constructor() { }
}
