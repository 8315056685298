import { Injectable } from '@angular/core';
declare var window: any;
@Injectable()
export class MapLoader {
  // このサービスは使ってない
  private static promise;
  map: google.maps.Map;

  public static load() {
    if (!MapLoader.promise) { // load once
      MapLoader.promise = new Promise((resolve) => {
        window['__onGapiLoaded'] = (ev) => {
          console.log('gapi loaded');
          resolve(window.gapi);
        };
        console.log('loading..');
        const node = document.createElement('script');
        node.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC0dUXPvwfAIjum43BhWnNictw3ALH2Pow&callback=__onGapiLoaded';
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    }

    return MapLoader.promise;
  }

  initMap(gmapElement, lat, lng) {

    return MapLoader.load().then((gapi) => {
      this.map = new google.maps.Map(gmapElement.nativeElement, {
        center: new google.maps.LatLng(lat, lng),
        zoom: 12
      });
    });
  }
}
