<div class="header">
  <div class="header__reservation" routerLink="/reservation"></div>
  <div class="header__logo" routerLink="/"  (click)="closeMenu()"></div>
  <div class="" [ngClass]="menuClosed ? 'header__open-menu--close' : 'header__open-menu--open'" (click)="openMenu()"></div>
</div>
<div [ngClass]="menuClosed ? 'overlay-menu-closed' : 'overlay-menu-opened'">
  <div class="menu">
    <ul>
      <li class="menu__bg01" routerLink="/about" (click)="closeMenu()">
        <div class="menu__button__title">ABOUT</div>
        <div class="menu__button__title-ja">山の家とは</div>
      </li>
      <li class="menu__bg08" routerLink="/reservation" (click)="closeMenu()">
        <div class="menu__button__title">RESERVATION</div>
        <div class="menu__button__title-ja">ご予約</div>
      </li>
      <li class="menu__bg06" routerLink="/rate" (click)="closeMenu()">
        <div class="menu__button__title">RATE</div>
        <div class="menu__button__title-ja">ご利用料金</div>
      </li>
      <li class="menu__bg02" routerLink="/topics" (click)="closeMenu()">
        <div class="menu__button__title">TOPICS</div>
        <div class="menu__button__title-ja">トピックス</div>
      </li>
      <li class="menu__bg03" routerLink="/facility" (click)="closeMenu()">
        <div class="menu__button__title">FACILITY</div>
        <div class="menu__button__title-ja">宿泊施設</div>
      </li>
      <li class="menu__bg04" routerLink="/outdoor" (click)="closeMenu()">
        <div class="menu__button__title">OUTDOOR</div>
        <div class="menu__button__title-ja">アウトドア</div>
      </li>
      <li class="menu__bg05" routerLink="/eat" (click)="closeMenu()">
        <div class="menu__button__title">EAT</div>
        <div class="menu__button__title-ja">お食事</div>
      </li>
      <li class="menu__bg07" routerLink="/access" (click)="closeMenu()">
        <div class="menu__button__title">ACCESS</div>
        <div class="menu__button__title-ja">アクセス</div>
      </li>
    </ul>
    <div class="menu__sns">
      <div class="menu_sns__box">
        <ol>
          <li>
            <div class="menu__sns__sns-twitter" (click)="openUrl('twitter')"></div>
          </li>
          <li>
            <div class="menu__sns__sns-facebook" (click)="openUrl('facebook')"></div>
          </li>
          <li>
            <div class="menu__sns__sns-instagram" (click)="openUrl('instagram')"></div>
          </li>
        </ol>
      </div>
    </div>
  </div>

</div>
<div [ngClass]="menuClosed ? 'mask-off' : 'mask-on'"></div>
