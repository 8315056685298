<div class="map">
  <img src="../../assets/images/map01.png">
</div>
<div *ngIf="apiLoaded | async">
  <google-map
    height="300px"
    width="100%"
    [center]="center"
    [zoom]="zoom"
    [options]="options"
    (mapClick)="openMap()"
  >
    <map-marker
      [position]="mapPosition"
      [options]="markerOptions"
    >
    </map-marker>
  </google-map>
</div>


