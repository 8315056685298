import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
// import {MapLoader} from '../services/map-loader.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  apiLoaded: Observable<boolean>;
  constructor(
    httpClient: HttpClient
  ) {
    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyC0dUXPvwfAIjum43BhWnNictw3ALH2Pow', 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }ee
  zoom = 9;
  mapPosition = {lat: 40.4774914, lng: 140.8252328};
  center: google.maps.LatLngLiteral = this.mapPosition;
  options: google.maps.MapOptions = {
    center: this.mapPosition,
    disableDefaultUI: true,
  };
  markerOptions: google.maps.MarkerOptions = {
  };
  // https://www.google.com/maps/@40.540213,140.909706,12z
  ngOnInit(): void {}
  openMap() {
    // tslint:disable-next-line:max-line-length
    window.open('https://www.google.com/maps/place/%E3%83%AC%E3%83%BC%E3%82%AF%E3%82%B5%E3%82%A4%E3%83%89%E5%B1%B1%E3%81%AE%E5%AE%B6/@40.4774914,140.8252328,17z/data=!4m18!1m9!3m8!1s0x5f9b2216975b57ed:0x724ae5d1c1de53df!2z44Os44O844Kv44K144Kk44OJ5bGx44Gu5a62!5m2!4m1!1i2!8m2!3d40.4774873!4d140.8274215!3m7!1s0x5f9b2216975b57ed:0x724ae5d1c1de53df!5m2!4m1!1i2!8m2!3d40.4774873!4d140.8274215', '_blank');
  }
}
