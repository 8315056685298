import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class BackendService {
  apiUrl: string;
  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = 'https://api.banto3.net/';
    // this.apiUrl = 'http://localhost:81/';
    this.setAppId('lake');
    this.setAppNameSpace('lake');
    this.setAppToken('poquee5uPhohtoed0ohquai1ceuNie7bie9Iechahf0ohyeisuteovie5coo7iwe');
  }
  public httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  async get(url: string, options = this.httpOptions): Promise<any> {
    return this.http.get(this.apiUrl + url, options)
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((e) => {
        throw e.error;
      });
  }
  async post(url: string, data: any, options = this.httpOptions): Promise<any> {
    return this.http.post(this.apiUrl + url, data, options)
      .toPromise()
      .then((res) => {
        const response: any = res;
        return response;
      })
      .catch((e) => {
        console.log(e);
        throw e.error;
      });
  }
  public setIdToken(token: string = null): void {
    if (!token) {
      return;
    }
    const bearerToken: any = `${token}`;
    this.httpOptions.headers = this.httpOptions.headers.set('idToken', bearerToken);
  }
  public setAppId(appId: string = null): void {
    if (!appId) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('x-appId', appId);
  }
  public setAppNameSpace(appNameSpace: string = null): void {
    if (!appNameSpace) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('x-appNameSpace', appNameSpace);
  }
  public setAppToken(token: string): void {
    if (!token) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('x-appToken', token);
  }
  public setAuth(token: string): void {
    if (!token) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', token);
  }
  public setContentType(contentType: string = null): void {
    if (!contentType) {
      return;
    }
    this.httpOptions.headers = this.httpOptions.headers.set('Content-Type', contentType);
  }
  public removeContentType(): void {
    this.httpOptions.headers = this.httpOptions.headers.delete('Content-Type');
  }
  public removeAuthorization(): void {
    this.httpOptions.headers = this.httpOptions.headers.delete('Authorization');
  }
}
