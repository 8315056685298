import { Component, OnInit } from '@angular/core';
import { StateService} from '../state.service';
import { PageTitleService} from '../page-title.service';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {
  currentPageTitle = 'TOP |';
  constructor(
    private stateService: StateService,
    private pageTitle: PageTitleService
  ) {
    this.stateService.isVisibleBreadcrumb = false;
    this.stateService.currentPage = false;
    this.stateService.currentPageName = this.currentPageTitle;
    this.pageTitle.changeTitle(this.currentPageTitle);
  }


  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
