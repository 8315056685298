import { Injectable } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  private baseTitle: string;
  constructor(
    private titleService: Title,
  ) { }
  public changeTitle( newTitle: string) {
    this.baseTitle = 'レークサイド山の家 秋田県 十和田湖のペットと泊まれるアウトドアを満喫できるお宿';
    this.titleService.setTitle( newTitle + this.baseTitle );
  }
}
