import { Component, OnInit } from '@angular/core';
import { StateService} from '../state.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  constructor(
    public stateService: StateService,
  ) {
  }
  ngOnInit(): void {
  }
}
