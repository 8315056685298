<div class="notice">
  <div class="notice__title">ご予約・お問い合わせについてのお知らせ</div>
  <div class="notice__body">
    <p>
      お客様へ<br>
      いつもレークサイド山の家をご愛顧いただきまして誠にありがとうございます。<br>
      今年の営業は終了致しました。<br>
      来年の営業は4月1日からの予定です。<br>
      また来年のご予約開始は1月1日からとさせて頂きます。<br>
      宜しくお願い申し上げます。
    </p>
  </div>
</div>
