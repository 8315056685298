import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }
  menuClosed = true;

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  openMenu() {
    this.menuClosed = !this.menuClosed;
  }
  closeMenu() {
    this.menuClosed = true;
  }
  openUrl(site) {
    switch (site) {
      case 'twitter':
        window.open('https://twitter.com/LakeYamanoie', '_blank');
        break;
      case 'facebook':
        window.open('https://www.facebook.com/pg/lakesideyamanoie', '_blank');
        break;
      case 'instagram':
        window.open('https://www.instagram.com/lakeside_towada/?hl=ja', '_blank');
        break;
    }
  }
}
