import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {TopComponent} from './top/top.component';
import {AboutComponent} from './about/about.component';
import {TopicsComponent} from './topics/topics.component';
import {FacilityComponent} from './facility/facility.component';
import {AccessComponent} from './access/access.component';
import {ReservationComponent} from './reservation/reservation.component';
import {EatComponent} from './eat/eat.component';
import {OutdoorComponent} from './outdoor/outdoor.component';
import {RateComponent} from './rate/rate.component';


const routes: Routes = [
  { path: 'about', component: AboutComponent},
  { path: 'topics', component: TopicsComponent},
  { path: 'facility', component: FacilityComponent},
  { path: 'access', component: AccessComponent},
  { path: 'reservation', component: ReservationComponent},
  { path: 'eat', component: EatComponent},
  { path: 'outdoor', component: OutdoorComponent},
  { path: 'rate', component: RateComponent},
  { path: '', component: TopComponent},
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
