import {AfterViewInit, Component} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-twitter',
  templateUrl: './twitter.component.html',
  styleUrls: ['./twitter.component.scss']
})
export class TwitterComponent implements AfterViewInit {

  constructor(
    private sanitizer: DomSanitizer
  ) { }
  tweet: SafeHtml;
  ngAfterViewInit() {
    const element = document.createElement('a'); // aタグを作ります
    element.setAttribute('href', 'https://twitter.com/ebisawa_a?ref_src=twsrc%5Etfw');
    element.setAttribute('class', 'twitter-timeline');
    // element.setAttribute('data-size', 'large');
    element.setAttribute('data-height', '400');
    element.setAttribute('data-show-count', 'false');
    // element.setAttribute('data-hashtags', 'Tweet #ebisawa');
    element.setAttribute('data-show-count', 'false');
    const script = document.createElement('script'); // scriptタグを作ります
    script.async = true;
    script.setAttribute('src', 'https://platform.twitter.com/widgets.js');
    script.setAttribute('charset', 'utf-8');
    // const div = document.getElementById('twitter'); // ボタンを置きたい場所の手前の要素を取得
    // div.parentNode.insertBefore(element, div.nextSibling); // ボタンを置きたい場所にaタグを追加
    // div.parentNode.insertBefore(script, div.nextSibling); // scriptタグを追加してJSを実行し、aタグをボ
  }

}
