<div class="reservation">
  <div class="title-box">
    <h2>RESERVATION</h2>
    <div class="subtitle">ご予約・お問い合わせ</div>
    <div class="headline" *ngIf="sendSuccess">ご入力頂いた内容を送信しました。ありがとうございました。</div>
  </div>
  <app-notice></app-notice>
  <form [formGroup]="inquiryForm" class="c-form" *ngIf="!sendSuccess">

    <!--<div fxLayout="row">
      <div fxFlex="100"><h2>レークサイド山の家 臨時休業のお知らせ</h2></div>
    </div>
    <div fxLayout="row">
      <div fxFlex="100">
        <br>
        いつもレークサイド山の家をご利用頂きましてありがとうございます。<br>
        冬季の営業時におきまして厳寒のため設備の故障が発生致しました。<br>
        従いまして４月より暫くの間休業させて頂き改修工事を致します。<br>
        大変申し訳ございませんが営業再開までしばらくお待ち下さいますよう
        お願い申し上げます。<br>なお営業再開のお知らせは本サイトにてご報告
        させて頂きます。<br>
        <br>
        レークサイド山の家 <br> <br> <br> <br>
      </div>
    </div>-->

    <div class="c-form__input">
      <div fxLayout="row">
        <label fxFlex="100">お問い合わせタイプ＊</label>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="50">
          <input name="formType" formControlName="formType" id="formType01" (change)="changeIsReserve()" class="radio-input" type="radio" value="ご予約" [checked]="">
          <label for="formType01">ご予約</label>
        </div>
        <div fxFlex="50">
          <input name="formType" formControlName="formType" id="formType02" (change)="changeIsNotReserve()" class="radio-input"  type="radio" value="お問い合わせ">
          <label for="formType02">お問い合わせ</label>
        </div>
      </div>
      <div fxLayout="row">
        <span fxFlex="100" *ngIf="formType.invalid && formType.dirty">お問い合わせタイプは必須です。</span>
      </div>
      <div fxLayout="row"></div>
    </div>

    <div class="c-form__input">
      <div fxLayout="row">
        <label fxFlex="100">お名前＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill"  class="form--white" fxFlex="100">
          <mat-label>名前を入力してください。</mat-label>
          <input matInput placeholder="田中 三郎" [formControl]="guestName" required>
          <mat-error *ngIf="guestName.invalid"></mat-error>
        </mat-form-field>
        <br>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="guestName.invalid && guestName.dirty">お名前は必須です。</span>
      </div>
    </div>

    <div class="c-form__input" *ngIf="isReserve">
      <div fxLayout="row">
        <label fxLayout>郵便番号（ハイフン無し）＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill"  class="mr16 form--white" fxFlex="100">
          <mat-label>郵便番号ハイフン無し。</mat-label>
          <input class="zip" matInput placeholder="1600004" [formControl]="zipCode" (change)="updatePostalCode()">
          <mat-error *ngIf="email.invalid"></mat-error>
        </mat-form-field>
        <br>
        <button fxFlex="40" type="button" (click)="updatePostalCode()">郵便番号検索</button>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="zipCode.invalid && zipCode.dirty">郵便番号を正しい形式で入力してください。</span>
      </div>

    </div>

    <div class="c-form__input" *ngIf="isReserve">
      <div fxLayout="row">
        <label fxFlex>ご住所＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill"  class="form--white" fxFlex="100">
          <mat-label>住所を入力してください。</mat-label>
          <input matInput placeholder="東京都新宿区四谷1-19○○マンション403" [formControl]="address">
          <mat-error *ngIf="address.invalid"></mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="address.invalid && address.dirty">住所1は必須です。</span>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="address.invalid && address.dirty">ご住所を正しい形式で入力してください。</span>
      </div>
    </div>

    <div class="c-form__input">
      <div fxLayout="row">
        <label fxFlex="100">メールアドレス＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill"  class="form--white" fxFlex="100">
          <mat-label>メールアドレスを入力してください。</mat-label>
          <input matInput placeholder="メールアドレス" [formControl]="email" required>
          <mat-error *ngIf="email.invalid"></mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <span fxFlex="100" *ngIf="email.errors && email.dirty">メールアドレスは必須です。</span>
      </div>
      <div fxLayout="row">
        <span fxFlex="100" *ngIf="email.invalid && email.dirty">メールアドレスを正しい形式で入力してください。</span>
      </div>
    </div>

    <div class="c-form__input">
      <div fxLayout="row">
        <label fxFlex for="phoneNumber">電話番号（ハイフン無し）＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill"  class="form--white" fxFlex="100">
          <mat-label>電話番号を入力してください。</mat-label>
          <input id="phoneNumber"matInput placeholder="09012344567" [formControl]="phoneNumber">
          <mat-error *ngIf="phoneNumber.invalid"></mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="phoneNumber.invalid && phoneNumber.dirty">電話番号は必須です。</span>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="phoneNumber.invalid && phoneNumber.dirty">電話番号を正しい形式で入力してください。</span>
      </div>
    </div>

    <div class="c-form__input" *ngIf="isReserve">
      <div fxLayout="row">
        <label fxFlex for="reserveDate">ご予約希望日(2024年10月1日より受付いたします)</label>
      </div>
      <div fxLayout="row">
        <mat-form-field class="form--white">
          <mat-label>日付を選択</mat-label>
          <input matInput [matDatepicker]="picker" [min]="minDate" id="reserveDate" name="reserveDate" formControlName="reserveDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="reserveDate.invalid && reserveDate.dirty">ご予約希望日は必須です。</span>
      </div>
    </div>

    <div class="c-form__input" *ngIf="isReserve">
      <div fxLayout="row">
        <label fxFlex>ご利用日数（宿泊される日数）＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill"  class="form--white" fxFlex="100">
          <mat-label>ご利用日数を数値で入力してください。</mat-label>
          <input matInput placeholder="" [formControl]="days">
          <mat-error *ngIf="days.invalid"></mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="days.invalid">ご利用日数は必須です。数値のみを入力してください。</span>
      </div>
    </div>

    <div class="c-form__input" *ngIf="isReserve">
      <div fxLayout="row">
        <label fxFlex for="arrivalTime">到着予定時刻＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill" class="form--white">
          <mat-label>到着予定時刻</mat-label>
          <mat-select [formControl]="arrivalTime" id="arrivalTime">
            <mat-option value="">選択してください</mat-option>
            <mat-option *ngFor="let timeString of timeTable"
                        [value]="timeString.id">{{timeString.id}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="arrivalTime.invalid">到着予定時刻は必須です。</span>
      </div>
    </div>

    <div class="c-form__input" *ngIf="isReserve">
      <div fxLayout="row">
        <label fxFlex="100">コテージ利用/キャンプテント泊＊</label>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="30">
          <input name="cottage" formControlName="cottage" id="cottage01" class="radio-input" type="radio" value="B棟">
          <label for="cottage01">B棟 22,000円</label>
        </div>
        <div fxFlex="30">
          <input name="cottage" formControlName="cottage" id="cottage02"  class="radio-input"  type="radio" value="C棟">
          <label for="cottage02">C棟 22,000円</label>
        </div>
        <div fxFlex="30">
          <input name="cottage" formControlName="cottage" id="cottage03" class="radio-input"  type="radio" value="利用しない">
          <label for="cottage03">利用しない</label>
        </div>
        <div fxFlex="30">
          <input name="cottage" formControlName="cottage" id="cottage04"  class="radio-input"  type="radio" value="キャンプ">
          <label for="cottage04">キャンプ</label>
        </div>
      </div>
      <div fxLayout="row">
        <span fxFlex="100" *ngIf="cottage.invalid">コテージ利用/キャンプの選択は必須です。</span>
      </div>
      <div fxLayout="row"></div>
    </div>

    <div class="c-form__input" *ngIf="isReserve">
      <div fxLayout="row">
        <label fxFlex>人数 大人＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill"  class="form--white" fxFlex="100">
          <mat-label>人数 大人を数値で入力してください。</mat-label>
          <input matInput placeholder="" [formControl]="numOfGuestsAdultWithoutMembership">
          <mat-error *ngIf="numOfGuestsAdultWithoutMembership.invalid"></mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="numOfGuestsAdultWithoutMembership.invalid">人数 大人は必須です。</span>
      </div>
    </div>

    <div class="c-form__input" *ngIf="isReserve">
      <div fxLayout="row">
        <label fxFlex>人数 小人＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill"  class="form--white" fxFlex="100">
          <mat-label>人数 小人を数値で入力してください。</mat-label>
          <input matInput placeholder="" [formControl]="numOfGuestsChildrenWithoutMembership">
          <mat-error *ngIf="numOfGuestsChildrenWithoutMembership.invalid"></mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="numOfGuestsChildrenWithoutMembership.invalid">一般人数 小人は必須です。</span>
      </div>
    </div>

    <div class="c-form__input" *ngIf="isReserve">
      <div fxLayout="row">
        <label fxFlex="100">ペット同伴＊</label>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="50">
          <input name="withPetsAnswer" formControlName="withPetsAnswer" id="withPetsAnswer02" (change)="changeWithoutPets()" class="radio-input"  type="radio" value="ペットなし">
          <label for="withPetsAnswer02">ペットなし</label>
        </div>
        <div fxFlex="50">
          <input name="withPetsAnswer" formControlName="withPetsAnswer" id="withPetsAnswer01" (change)="changeWithPets()" class="radio-input" type="radio" value="ペットあり">
          <label for="withPetsAnswer01">ペットあり</label>
        </div>
      </div>
      <div fxLayout="row">
        <span fxFlex="100" *ngIf="withPetsAnswer.invalid">ペット同伴の選択は必須です。</span>
      </div>
      <div fxLayout="row"></div>
    </div>

    <div class="c-form__input" *ngIf="withPets && isReserve">
      <div fxLayout="row">
        <label fxFlex>小型犬 頭数＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill"  class="form--white" fxFlex="100">
          <mat-label>小型犬 頭数を数値で入力してください。</mat-label>
          <input matInput placeholder="" [formControl]="numOfDogSmall">
          <mat-error *ngIf="numOfDogSmall.invalid"></mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <span fxFlex *ngIf="numOfDogSmall.invalid">小型犬 頭数を入力してください。</span>
      </div>
    </div>

    <div class="c-form__input" *ngIf="withPets && isReserve">
      <div fxLayout="row">
        <label fxFlex>中型犬 頭数＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill"  class="form--white" fxFlex="100">
          <mat-label>中型犬 頭数を数値で入力してください。</mat-label>
          <input matInput placeholder="" [formControl]="numOfDogMid">
          <mat-error *ngIf="numOfDogMid.invalid"></mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" *ngIf="withPets">
        <span fxFlex *ngIf="numOfDogMid.invalid">中型犬 頭数を入力してください。</span>
      </div>
    </div>

    <div class="c-form__input" *ngIf="withPets && isReserve">
      <div fxLayout="row">
        <label fxFlex>大型犬 頭数＊</label>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="fill"  class="form--white" fxFlex="100">
          <mat-label>大型犬 頭数を数値で入力してください。</mat-label>
          <input matInput placeholder="" [formControl]="numOfDogLarge">
          <mat-error *ngIf="numOfDogLarge.invalid"></mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <span fxFlex *ngIf="numOfDogLarge.invalid">大型犬 頭数を入力してください。</span>
      </div>
    </div>

    <div class="c-form__input" *ngIf="withPets && isReserve">
      <div fxLayout="row">
        <label fxFlex>ペット情報（犬以外の場合など）</label>
      </div>
      <div fxLayout="row">
        <textarea id="infoOfPets" name="infoOfPets" formControlName="infoOfPets"></textarea>
      </div>
      <div fxLayout="row">
      </div>
    </div>

    <div class="c-form__input">
      <div fxLayout="row">
        <label fxFlex>お問合わせ事項</label>
      </div>
      <div fxLayout="row">
        <textarea id="inquiryBody" name="inquiryBody" formControlName="inquiryBody"></textarea>
      </div>
      <div fxLayout="row">
      </div>
    </div>


    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="c-form__apply" fxFlex="">
        <!--<button type="submit" class="c-form__apply__button" (click)="submit()" *ngIf="!isProcessing">送信する</button>-->
        <button type="submit" class="c-form__apply__button" disabled (click)="submit()" *ngIf="!isProcessing">現在受付を停止しております</button>
        <button type="submit" class="c-form__apply__processing" *ngIf="isProcessing">送信中...</button>
      </div>
    </div>
  </form>
</div>

