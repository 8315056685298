<div class="left-side">
  <div class="left-side__left">
    <div class="" [ngClass]="menuClosed ? 'left-side__left__open-menu--close' : 'left-side__left__open-menu--open'" (click)="openMenu()"></div>
    <div class="left-side__left__logo"></div>
    <div class="left-side__left__sns-twitter" (click)="openUrl('twitter')"></div>
    <div class="left-side__left__sns-facebook" (click)="openUrl('facebook')"></div>
    <div class="left-side__left__sns-instagram" (click)="openUrl('instagram')"></div>
    <div class="left-side__left__reservation" routerLink="/reservation"></div>
  </div>
</div>
<div [ngClass]="menuClosed ? 'overlay-menu-closed--side' : 'overlay-menu-opened--side'">
  <div class="menu--side">
    <ul>
      <li class="menu--side__bg08" routerLink="/reservation" (click)="closeMenu()">
        <div class="menu--side__button__title">RESERVATION</div>
        <div class="menu--side__button__title-ja">ご予約</div>
      </li>
      <li class="menu--side__bg06" routerLink="/rate" (click)="closeMenu()">
        <div class="menu--side__button__title">RATE</div>
        <div class="menu--side__button__title-ja">ご利用料金</div>
      </li>
      <li class="menu--side__bg01" routerLink="/about" (click)="closeMenu()">
        <div class="menu--side__button__title">ABOUT</div>
        <div class="menu--side__button__title-ja">山の家とは</div>
      </li>
      <li class="menu--side__bg02" routerLink="/topics" (click)="closeMenu()">
        <div class="menu--side__button__title">TOPICS</div>
        <div class="menu--side__button__title-ja">トピックス</div>
      </li>
      <li class="menu--side__bg03" routerLink="/facility" (click)="closeMenu()">
        <div class="menu--side__button__title">FACILITY</div>
        <div class="menu--side__button__title-ja">宿泊施設</div>
      </li>
      <li class="menu--side__bg04" routerLink="/outdoor" (click)="closeMenu()">
        <div class="menu--side__button__title">OUTDOOR</div>
        <div class="menu--side__button__title-ja">アウトドア</div>
      </li>
      <li class="menu--side__bg05" routerLink="/eat" (click)="closeMenu()">
        <div class="menu--side__button__title">EAT</div>
        <div class="menu--side__button__title-ja">お食事</div>
      </li>
      <li class="menu--side__bg07" routerLink="/access" (click)="closeMenu()">
        <div class="menu--side__button__title">ACCESS</div>
        <div class="menu--side__button__title-ja">アクセス</div>
      </li>
    </ul>
  </div>
</div>
<div [ngClass]="menuClosed ? 'mask-off--side' : 'mask-on--side'"></div>
