<div class="facility">
  <div class="title-box">
    <h2>FACILITY</h2>
    <div class="subtitle">宿泊施設</div>
  </div>


  <div class="title-box mb1">
    <h2>A棟</h2>
  </div>
  <div class="image-box">
    <img src="../../assets/images/facility/facility1.png">
  </div>
  <div class="image-box">
    <img src="../../assets/images/facility/facility2.png">
  </div>
  <div class="title-box">
    <div class="headline">
      ●和室（10畳/4室・8畳/2室） ●大広間 ●食堂 ●浴室
    </div>
  </div>


  <div class="title-box mt3 mb1">
    <h2>B棟（１棟２戸）</h2>
  </div>
  <div class="image-box">
    <img src="../../assets/images/facility/facility3.png">
  </div>
  <div class="title-box">
    <div class="headline">
      B棟（1棟2戸）
    </div>
  </div>


  <div class="title-box mt3">
    <h2>C棟（１棟２戸）</h2>
  </div>
  <div class="image-box">
    <img src="../../assets/images/facility/facility4.jpg">
  </div>
  <div class="image-box">
    <img src="../../assets/images/facility/facility5.jpg">
  </div>
  <div class="title-box mb3">
    <div class="headline">
      C棟（1棟2戸）<br>
    </div>
  </div>



</div>
