import {Component, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from './google-analytics.service';
import { StateService} from './state.service';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private baseTitle: string;
  public constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private stateService: StateService,
  ) { }
  ngOnInit() {
    // googleAnalytics tracking
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((params: any) => {
        this.googleAnalyticsService.sendPageView(params.url);
      });
  }
}
