<div class="access">
  <div class="title-box">
    <h2>ACCESS</h2>
    <div class="subtitle">アクセス</div>
  </div>
  <app-map></app-map>
  <div class="headline">
    レークサイド 山の家<br>
    〒018-5511秋田県鹿角郡小坂町十和田湖字銀山1-7<br>
    TEL 0176-75-2552<br>
  </div>

  <div class="title-box">
    <h2>自動車</h2>
    <div class="subtitle"></div>
  </div>
  <div class="image-box--full">
    <img src="../../assets/images/bycar_s1.jpg">
  </div>
  <div class="headline">
    ●青森から城ヶ倉経由で約１時間３０分<br>
    ●青森から酸ヶ湯・奥入瀬渓流経由で約３時間<br>
    ●東北自動車道　黒石Ｉ．Ｃ、小坂Ｉ．Ｃより約４０分、十和田ＩＣより約４５分<br>
    ●十和田湖休屋より約２０分
  </div>

  <div class="title-box">
    <h2>自動車</h2>
    <div class="subtitle"></div>
  </div>
  <div class="image-box--full">
    <img src="../../assets/images/bybus_s1.jpg">
  </div>
  <div class="headline">
    ●青森駅からJRみずうみ号で休屋まで3時間<br>
    ●八戸駅からJRおいらせ号で休屋まで2時間20分
  </div>


</div>
