<div class="rate mb3">
  <div class="title-box">
    <h2>RATE</h2>
    <div class="subtitle">ご利用料金</div>
  </div>

  <div class="image-box">
    <img src="../../assets/images/facility/facility1.png" alt="レークサイド山の家">
  </div>

  <div class="title-box">
    <div class="headline">
      <table>
        <tr>
          <td colspan="4">ご宿泊料金と営業期間 (2024年4月現在)</td>
        </tr>
        <tr>
          <td rowspan="3">宿泊A棟<br>（和室）</td>
          <td>大人</td>
          <td>5,500円</td>
          <td rowspan="2">お一人様で一室ご利用の場合は1,100円追加とさせて頂きます。</td>
        </tr>
        <tr>
          <td>お子様(4歳〜12歳)</td>
          <td>4,400円</td>
        </tr>
        <tr>
          <td>3歳未満</td>
          <td>無料</td>
          <td>３歳未満のお客様にはリネン類等は含まれません。</td>
        </tr>

        <tr>
          <td>宿泊B棟<br>（コテージ）</td>
          <td>4名様まで</td>
          <td>22,000円</td>
          <td>最大８名様まで宿泊可能です。<br>
            ５名様以上はお一人に付き2,200円の追加料金 が発生します。
          </td>
        </tr>

        <tr>
          <td>宿泊C棟<br>（コテージ）</td>
          <td>4名様まで</td>
          <td>22,000円</td>
          <td>最大5名様まで宿泊可能です。<br>
            5名様以上はお一人に付き2,200円の追加料金 が発生します。
          </td>
        </tr>

        <tr>
          <td rowspan="2">キャンプ</td>
          <td>車中泊・お車1台につき</td>
          <td>3,300円</td>
          <td>
            運転者様・同乗者様4名様までご利用可能です。<br>
            5名様以上はお一人様に付き1,100円の追加料金とさせて頂きます。
          </td>
        </tr>
        <tr>
          <td>テント泊・お車1台につき</td>
          <td>5,500円</td>
          <td>
            運転者様・同乗者様合計4名様までご利用可能です。<br>
            5名様以上はお一人様に付き1,100円の追加料金とさせて頂きます。
          </td>
        </tr>

        <tr>
          <td rowspan="3">ペット<br>（コテージのみ）</td>
          <td>小型</td>
          <td>1,100円</td>
          <td rowspan="3">
            コテージ寝室にはご入室は出来ません。<br>
            病気・ケガ等の発生の原因や他のお客様へのご迷惑となる事や、下記の事項に該当する場合はホテルをご利用出来ません。<br>
            <br>
            ①ノミ・ダニが寄生している場合<br>
            ②伝染病または持病（皮膚病も含む）のある場合<br>
            ③混合ワクチン接種証明書および狂犬病予防接種証明書をお忘れの場合
          </td>
        </tr>
        <tr>
          <td>中型</td>
          <td>2,200円</td>
        </tr>
        <tr>
          <td>大型</td>
          <td>3,300円</td>
        </tr>
        <tr>
          <td>営業期間</td>
          <td colspan="3">4月20日～11月10日（天候その他の諸条件により営業できない場合もございます）</td>
        </tr>

      </table>
    </div>
    <p style="text-align: left">４月１日より受付開始</p>
  </div>

  <!--<div class="title-box">
    <div class="headline">
      <table>
        <tr>
          <td rowspan="3">ペット宿泊料金</td>
          <td>小型犬</td>
          <td>1,000円</td>
        </tr>
        <tr>
          <td>中型犬</td>
          <td>1,500円</td>
        </tr>
        <tr>
          <td>大型犬</td>
          <td>2,000円</td>
        </tr>
      </table>
    </div>
  </div>-->

  <!--<div class="title-box">
    <div class="headline">
      <table>
        <tr>
          <td>オンシーズン</td>
          <td>春</td>
          <td>4/25〜5/9</td>
        </tr>
        <tr>
          <td>オンシーズン</td>
          <td>夏</td>
          <td>7/20〜8/29</td>
        </tr>
        <tr>
          <td>オンシーズン</td>
          <td>秋</td>
          <td>10/5〜11/10</td>
        </tr>
        <tr>
          <td>営業期間</td>
          <td>4/18〜11/15</td>
          <td>4月1日より受付開始します。天候などにより急遽変更する場合があります。</td>
        </tr>
      </table>
    </div>
  </div>-->

  <div class="caution">
    1.金額は1泊した場合の料金です。<br>
    2.ペットのご宿泊はご予約状況によってはお請けできない場合があります。<br>
    <br>
    <b>キャンセルについて</b><br>
    キャンセル料は以下の通り頂戴いたします。<br>
    当日　　　　：宿泊料金の100%<br>
    前日　　　　：宿泊料金の50%<br>
    3日前から：宿泊料金の30%<br>
    7日前から：宿泊料金の20%<br><br><br>

    <b>ペット同伴時のお願い</b><br>
    ・ペットは１階のみでお過ごしください。<br>
    ・部屋に備え付けのタオルや食器は使用しないでください。<br>
    ・散歩時はリードをご着用ください。<br>
    ・排泄物はお持ち帰りください。<br><br>

    <b>ご用意いただくもの</b><br>

    ・狂犬病、混合ワクチン接種証明書の写し（１年以内のもの）<br>
    ・ペット用ケージ<br>
    ・ペット用の食事、食器、寝具、トイレ用品など<br>
  </div>




</div>
