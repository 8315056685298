<div class="outdoor">
  <div class="title-box">
    <h2>OUTDOOR</h2>
    <div class="subtitle">アウトドア</div>
  </div>

  <div class="image-box">
    <img src="../../assets/images/outdoor/outdoor.jpg">
  </div>
  <div class="headline">
    <table>
      <tr>
        <td>モーターボート</td>
        <td>5,000円/１時間～</td>
      </tr>
      <tr>
        <td>手漕ぎボート</td>
        <td>無料</td>
      </tr>
      <tr>
        <td>レークツアー プレジャーボート</td>
        <td>4,000円/40分お一人様（3名様以上）</td>
      </tr>
      <tr>
        <td>インフレータブルボート</td>
        <td>3,500円/40分お一人様（3名様以上）</td>
      </tr>
      <tr>
        <td>オートキャンプ</td>
        <td>
          2,750円/１台一泊～<br>
          別途お一人様1,650円
        </td>
      </tr>
      <tr>
        <td>キャンプファイヤー</td>
        <td>2,000円/１回の使用料</td>
      </tr>
      <tr>
        <td>レンタサイクル</td>
        <td>無料</td>
      </tr>
    </table>
  </div>


  <div class="title-box">
    <h2>フィッシング</h2>
    <div class="headline">
      <table>
        <tr>
          <td>魚の種類</td>
          <td>期間</td>
          <td>遊漁料</td>
        </tr>
        <tr>
          <td>ヒメマス 船釣り</td>
          <td>
            4月1日～6月20日<br>
            7月11日～7月20日<br>
            10月1日～12月31日
          </td>
          <td>2,400円/１日お一人様<br>手釣り、さお釣り １人当たり2本</td>
        </tr>
        <tr>
          <td>ヒメマス 岸釣り</td>
          <td>
            7月11日～7月20日<br>
            10月1日～翌年6月20日
          </td>
          <td>1,200円/１日お一人様<br>手釣り、さお釣り １人当たり2本</td>
        </tr>
        <tr>
          <td>サクラマス 船釣り</td>
          <td>6月1日～6月20日<br>
            7月11日～720日<br>
            12月1日～翌年2月末日まで</td>
          <td>2,600円/１日お一人様<br>手釣り、さお釣り １人当たり2本</td>
        </tr>
        <tr>
          <td>サクラマス 岸釣り</td>
          <td>6月1日～6月20日<br>
            7月11日～720日<br>
            12月1日～翌年2月末日まで</td>
          <td>1,300円/１日お一人様<br>手釣り、さお釣り １人当たり2本</td>
        </tr>
        <tr>
          <td>コイ・フナ</td>
          <td>7月21日～9月30日</td>
          <td>250円/１日お一人様<br>手釣り、さお釣り １人当たり2本</td>
        </tr>
        <tr>
          <td>えび</td>
          <td></td>
          <td>1,000円/１日お一人様<br>せん １人当たり10個</td>
        </tr>
        <tr>
          <td>お風呂利用 宿泊者</td>
          <td>開業シーズンのみ</td>
          <td>無料</td>
        </tr>
        <tr>
          <td>お風呂利用 宿泊者</td>
          <td>それ以外の方</td>
          <td>1,000円/１日お一人様</td>
        </tr>
        <tr>
          <td colspan="3">
            ボート貸し出し料金は別途発生いたします
          </td>
        </tr>
      </table>

    </div>
  </div>



</div>
