import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {ZipCode} from './zip';

@Injectable({
  providedIn: 'root'
})
export class ZipService {

  constructor(private http: HttpClient) {
  }
  private zipCodeResponse: ZipCode;
  private apiKey: string = environment.postalCodeApiKey;
  private host = 'https://apis.postcode-jp.com/api/v4/postcodes/';

  private httpOptions: any = {
    // ヘッダ情報
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    body: null
  };

  public get(zipCode): Promise<ZipCode> {
    return this.http.get(this.host + zipCode + '?apiKey=' + this.apiKey, this.httpOptions)
      .toPromise()
      .then((res) => {
        const response: any = res;
        this.zipCodeResponse = response;
        return this.zipCodeResponse;
      })
      .catch();
  }

  private errorHandler(err) {
    console.log('Error occured.', err);
    return Promise.reject(err.message || err);
  }
}
