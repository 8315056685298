import { Component, OnInit } from '@angular/core';
import {StateService} from '../state.service';
import {PageTitleService} from '../page-title.service';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnInit {

  currentPageTitle = 'RATE ご利用料金  ';
  constructor(
    private stateService: StateService,
    private pageTitle: PageTitleService
  ) {
    this.stateService.isVisibleBreadcrumb = true;
    this.stateService.currentPage = true;
    this.stateService.currentPageName = this.currentPageTitle;
    this.pageTitle.changeTitle(this.currentPageTitle);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
