<div id="twitter" class="twitter">
  <ul>
    <li>
      <div class="twitter__box">
        <div class="twitter__image">
          <img src="../../assets/images/menu/menu1.jpg">
        </div>
        <div class="twitter__date">2020.03.01</div>
        <div class="twitter__body">レークサイド山の家ホームページをリニューアルしました。</div>
      </div>
    </li>
    <li>
      <div class="twitter__box">
        <div class="twitter__image">
          <img src="../../assets/images/menu/menu2.jpg">
        </div>
        <div class="twitter__date">2020.03.01</div>
        <div class="twitter__body">山の家の運営方法が変わります。詳しくはSNSでお知らせします。</div>
      </div>
    </li>
    <li>
      <div class="twitter__box">
        <div class="twitter__image">
          <img src="../../assets/images/menu/menu4.jpg">
        </div>
        <div class="twitter__date">2020.03.01</div>
        <div class="twitter__body">レークサイド山の家では従業員を募集しています。</div>
      </div>
    </li>
  </ul>
</div>

