<div class="eat">
  <div class="title-box">
    <h2>EAT</h2>
    <div class="subtitle">お食事</div>
  </div>
  <div class="image-box">
  </div>
  <div class="headline">
    <table>
      <tr>
        <td>バラ焼き定食</td>
        <td>1,000円</td>
        <td rowspan="5">現在試作中</td>
      </tr>
      <tr>
        <td>シャモロックラーメン</td>
        <td>1,000円</td>
      </tr>
      <tr>
        <td>うどん・そば</td>
        <td>700円〜</td>
      </tr>
      <tr>
        <td>ヒメマス定食</td>
        <td>1,800円</td>
      </tr>
      <tr>
        <td>サクラマス定食</td>
        <td>1,800円</td>
      </tr>
    </table>
  </div>
</div>
