import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { MatDialogModule} from '@angular/material/dialog';
import { MatSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectModule} from '@angular/material/select';
import { MatInputModule} from '@angular/material/input';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ReactiveFormsModule} from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {OverlayModule} from '@angular/cdk/overlay';
import { AppComponent } from './app.component';
import { TopComponent } from './top/top.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SideBarComponent } from './side-bar/side-bar.component';
import { MenuComponent } from './menu/menu.component';
import { TwitterComponent } from './twitter/twitter.component';
import { MapComponent } from './map/map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AccessComponent } from './access/access.component';
import { TopicsComponent } from './topics/topics.component';
import { AboutComponent } from './about/about.component';
import { FacilityComponent } from './facility/facility.component';
import { OutdoorComponent } from './outdoor/outdoor.component';
import { EatComponent } from './eat/eat.component';
import { ReservationComponent } from './reservation/reservation.component';
import { RateComponent } from './rate/rate.component';
import { TopicComponent } from './topic/topic.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {InfoDialogComponent} from './info-dialog/info-dialog.component';
import {LeftSideComponent} from './left-side/left-side.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import {AngularFirestore} from '@angular/fire/firestore';
import {MapLoader} from './services/map-loader.service';
import { ScullyLibModule } from '@scullyio/ng-lib';
import {NoticeComponent} from './notice/notice.component';
import {AngularFireFunctions, AngularFireFunctionsModule, REGION} from '@angular/fire/functions';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    TopComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    SideBarComponent,
    MenuComponent,
    TwitterComponent,
    MapComponent,
    AccessComponent,
    TopicsComponent,
    AboutComponent,
    FacilityComponent,
    OutdoorComponent,
    EatComponent,
    ReservationComponent,
    RateComponent,
    TopicComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    LeftSideComponent,
    BreadcrumbComponent,
    NoticeComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        GoogleMapsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatMenuModule,
        MatButtonModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        OverlayModule,
        AngularFireModule.initializeApp(environment.firebaseConfig)
    ],
  providers: [
    MatSnackBar,
    ConfirmDialogComponent,
    InfoDialogComponent,
    MatSpinner,
    AngularFireModule,
    // AngularFirestore,
    AngularFireFunctionsModule,
    AngularFireFunctions,
    MapLoader,
    {provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},
    { provide: REGION, useValue: 'us-central1' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
