import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-left-side',
  templateUrl: './left-side.component.html',
  styleUrls: ['./left-side.component.scss']
})
export class LeftSideComponent implements OnInit {
  constructor(
  ) {
  }
  menuClosed = true;

  ngOnInit() {
  }
  openMenu() {
    this.menuClosed = !this.menuClosed;
  }
  closeMenu() {
    this.menuClosed = true;
  }
  openUrl(site) {
    switch (site) {
      case 'twitter':
        window.open('https://twitter.com/LakeYamanoie', '_blank');
        break;
      case 'facebook':
        window.open('https://www.facebook.com/pg/lakesideyamanoie', '_blank');
        break;
      case 'instagram':
        window.open('https://www.instagram.com/lakeside_towada/?hl=ja', '_blank');
        break;
    }
  }

}
