import { Component, OnInit } from '@angular/core';
import { StateService} from '../state.service';
import { PageTitleService} from '../page-title.service';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  currentPageTitle = 'ABOUT 自然と歴史 十和田湖の魅力  ';
  constructor(
    private stateService: StateService,
    private pageTitle: PageTitleService
  ) {
    this.stateService.isVisibleBreadcrumb = true;
    this.stateService.currentPage = true;
    this.stateService.currentPageName = this.currentPageTitle;
    this.pageTitle.changeTitle(this.currentPageTitle);
  }
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
